<template>
    <div class="container-fluid pl-0 pr-5">
        <div class="row">
            <div class="col-lg-4">
                <country @success="loadCountry" @refresh="RefreshData"></country>
            </div>
            <div class="col-lg-4">
                <state ref="state" @success="loadState" @refresh="RefreshData"></state>
            </div>
            <div class="col-lg-4 w-40r">
                <district ref="district"></district>
            </div>

        </div>

    </div>

</template>

<script>

import Country from './country';
import State from './state';
import District from './district';

export default {
    name: 'Location',
    components: { District, State, Country },
    data () {
        return {};
    },
    methods: {
        loadCountry () {
            this.$refs.state.loadCountry();
        },
        loadState () {
            this.$refs.district.loadState();
        },
        RefreshData () {
            this.$refs.state.refreshData();
            this.$refs.district.refreshData();
        }
    }

};
</script>

<style scoped>

</style>
